import React from "react"
import { useState } from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Maps from "../components/map"
import Button from "../components/button"

// Utilities
import kebabCase from "lodash/kebabCase"
import { heatMapColorforValue } from "../components/functions"

const CoursePage = props => {
  //define all the constants we need
  const { data } = props
  const [toggleViewMode, setToggleViewMode] = useState(true);
  const emptyQuery = ""
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })
  const { filteredData, query } = state
  // if we have a fileredData in state and a non-emptyQuery then
  // searchQuery then `hasSearchResults` is true
  const hasSearchResults = filteredData && query !== emptyQuery

  const allPosts = data.allMarkdownRemark.group
  const title = data.site.siteMetadata.title
  //console.log(allPosts)

  // if we have a search query then return filtered data instead of all posts; else return allPosts
  const posts = hasSearchResults ? filteredData : allPosts

  //Here is where we handle the filtering, once we start typing
  const handleInputChange = event => {
      const query = event.target.value
      const { data } = props
  
      // this is how we get all of our posts
      const posts = data.allMarkdownRemark.group || []
      // return all filtered posts
      const filteredData = posts.filter(post => {
      // destructure data from post frontmatter
      const fieldValue = post.fieldValue
      return (
        // standardize data with .toLowerCase()
        // return true if the fieldValue contains the query string
        fieldValue.toLowerCase().includes(query.toLowerCase())
        //null
       )
    })

    // update state according to the latest query and results
    setState({
      query, // with current query string from the `Input` event
      filteredData, // with filtered data from posts.filter(post => (//filteredData)) above
    })
  }

return (
<Layout location="Courses" title={title}>
<Seo title="All Courses" />
  <article>
    <div>
      <div className="flexcontainer">
      <h1 style={{ margin: "revert"}}><span className={posts.length !== allPosts.length ? "filtertext" : null }>{posts.length}</span> Course{posts.length === 1 ? '' : 's'}</h1>
        <input
                type="text"
                className="inputbox"
                aria-label="Search"
                placeholder="Type to filter..."
                onChange={handleInputChange}
        />
        <div className="togglebuttons">
          <Button name='List' active={toggleViewMode ? 1 : 0} click={() => setToggleViewMode(1)}/>
          <Button name='Map' active={toggleViewMode ? 0 : 1} click={() => setToggleViewMode(0)}/>
        </div>
      </div>
      { toggleViewMode ?
       <ul className="tag-results">
        {posts.map(course => (
          <li key={course.fieldValue}>
            <div style={heatMapColorforValue(course.totalCount, "187, 80, 6")}>
              <Link to={course.totalCount === 1 ? course.nodes[0].fields.slug : ("/courses/" + kebabCase(course.fieldValue))}>
              <div className="flexcontainer">
              <div>{course.fieldValue}</div><div className="details">{course.totalCount !== 1 ? "(" + course.totalCount + ")" : null}</div>
              </div>
              </Link>
            </div>
          </li> 
        ))}
      </ul> :
       <div className="boxofmap">
       <Maps data={posts} />
     </div>
      }
    </div>
  </article>
</Layout>
)
}

CoursePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default CoursePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { tags: {nin: ["not-golf", "upcoming"]} } }
      ) {
      group(field: frontmatter___title) {
        fieldValue
        totalCount
        nodes {
          frontmatter {
            coursegps
          }
          fields {
            slug
          }
        }
      }
    }
  }
`