import React from "react";

function Button(props) {

  return (
    <div className={`togglebuttons ${props.active ? 'bottomline' : ''}`}>
  <button
   onClick={props.click}
   className= {props.active ? 'buttonactive' : 'buttonnotactive'}
  >
    {props.name}
  </button>
</div>
  )
}

export default Button;
