/**
 * Map Component
 */
import * as React from "react";
import {useState, useMemo} from 'react';
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase";

import mapboxgl from "mapbox-gl"

import Map, {
   Marker,
   Popup,
   NavigationControl,
   FullscreenControl,
   ScaleControl,
 } from 'react-map-gl';
 import "mapbox-gl/dist/mapbox-gl.css"

//This stuff below fixes transpilation issues on Netlify build...this is a mapbox v2 problem
//https://github.com/mapbox/mapbox-gl-js/issues/10565#issuecomment-899083108
/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default
/* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires*/

const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN

const Maps = (posts) => {

   const [popupInfo, setPopupInfo] = useState(null);
   const pins = useMemo(
      () =>
      posts.data.map(course => (
          <Marker
            key={course.fieldValue}
            longitude={course.nodes[0].frontmatter.coursegps.split(',')[1]}
            latitude={course.nodes[0].frontmatter.coursegps.split(',')[0]}
            anchor="bottom"
            onClick={e => {
              // If we let the click event propagates to the map, it will immediately close the popup
              // with `closeOnClick: true`
              e.originalEvent.stopPropagation();
              setPopupInfo(course);
            }}
          >
          </Marker>
        )),
      [posts.data]
    );

   return (

      <>
      <Map reuseMaps
        initialViewState={{
          latitude: 35.84,
          longitude: -78.93,
          zoom: 0,
          bearing: 0,
          pitch: 0
        }}
        mapStyle="mapbox://styles/mapbox/outdoors-v11"
        style={{height: 600}}
        mapboxAccessToken={MAPBOX_TOKEN}
      >

         <FullscreenControl position="top-left" />
         <NavigationControl position="top-left" />
         <ScaleControl />

         {pins}

        {popupInfo && (
         <Link to={`/courses/${kebabCase(popupInfo.fieldValue)}/`}>
          <Popup
            anchor="top"
            longitude={Number(popupInfo.nodes[0].frontmatter.coursegps.split(',')[1])}
            latitude={Number(popupInfo.nodes[0].frontmatter.coursegps.split(',')[0])}
            onClose={() => setPopupInfo(null)}
          >
            <div>
              {popupInfo.fieldValue}
            </div>
          </Popup>
          </Link>
        )}
      </Map>

    </>  
   

   );
 }
 
export default Maps
